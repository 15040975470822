import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	AreaChartOutlined,
	BorderOuterOutlined,
	CodeSandboxOutlined,
	ColumnWidthOutlined,
	CompassOutlined,
	FileDoneOutlined,
	IssuesCloseOutlined,
	RadarChartOutlined,
	ScanOutlined,
	TeamOutlined,
	UnorderedListOutlined,
	UserOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { useUser } from '../../Context/User';
import { saveToLocalStorage } from '../Login/storage';

export function MainMenu({
	setSelectedMenu,
	selectedMenu,
	setVisible,
	mode = 'inline',
}) {
	const { t } = useTranslation();

	const { user } = useUser();

	const allItems = [
		{
			key: 'orders',
			icon: <UnorderedListOutlined />,
			label: <Link to="/orders/">{t('control.orders')}</Link>,
		},
		{
			key: 'picking',
			icon: <ScanOutlined />,
			label: <Link to="/picking/">{t('control.picking')}</Link>,
			roleIds: [2, 3, 5, 6, 8],
		},
		{
			key: 'picking_box',
			icon: <CodeSandboxOutlined />,
			label: <Link to="/picking_box/">{t('control.picking_box')}</Link>,
			roleIds: [2, 3, 5, 6, 8],
		},
		{
			key: 'following',
			icon: <CompassOutlined />,
			label: <Link to="/following/">{t('control.following')}</Link>,
			roleIds: [2, 3, 5, 6, 8],
		},
		{
			key: 'routes-following',
			icon: <BorderOuterOutlined />,
			label: <Link to="/routes-following/">{t('control.routes-following')}</Link>,
			roleIds: [2, 3, 5, 6, 8],
		},
		{
			key: 'employee-reporting',
			icon: <RadarChartOutlined />,
			label: <Link to="/employee-reporting/">{t('control.employee-reporting')}</Link>,
			roleIds: [2, 3, 5, 6, 8],
		},
		{
			key: 'forecast',
			icon: <AreaChartOutlined />,
			label: <Link to="/forecast/">{t('control.forecast')}</Link>,
			roleIds: [2, 3, 5, 6, 8],
		},
		{
			key: 'orders_exceptions',
			icon: <IssuesCloseOutlined />,
			label: <Link to="/orders_exceptions/">{t('control.orders_exceptions')}</Link>,
			roleIds: [2, 3, 5, 6, 8],
		},
		{
			key: 'mrw',
			icon: <ColumnWidthOutlined />,
			label: <Link to="/mrw/">{t('control.mrw')}</Link>,
			roleIds: [3, 8],
		},
		{
			key: 'mrw_multiple',
			icon: <CodeSandboxOutlined />,
			label: <Link to="/mrw_multiple/">{t('control.mrw_multiple')}</Link>,
			roleIds: [3, 8],
		},
		{
			key: 'label_multiple',
			icon: <CodeSandboxOutlined />,
			label: <Link to="/label_multiple/">{t('control.label_multiple')}</Link>,
			roleIds: [3, 8],
		},
		{
			key: 'employees',
			icon: <UserOutlined />,
			label: <Link to="/employees/">{t('control.employees')}</Link>,
			roleIds: [8],
		},
		{
			key: 'fleets',
			icon: <TeamOutlined />,
			label: <Link to="/fleets/">{t('control.fleets')}</Link>,
			roleIds: [8],
		},
		{
			key: 'manifests',
			icon: <FileDoneOutlined />,
			label: <Link to="/manifests/">{t('control.manifests')}</Link>,
			roleIds: [3, 8],
		},
	];

	const items = allItems.map((item) => {
		if (item.roleIds?.length) {
			if (item.roleIds.includes(user?.roleId)) {
				return item;
			} else {
				return;
			}
		}
		return item;
	});
	const selectedMenuItem = ({ item, key }) => {
		saveToLocalStorage('title', key);
		setSelectedMenu(key);
		setVisible(false);
	};
	return (
		<Menu
			theme="light"
			mode={mode}
			style={{ backgroundColor: 'transparent', color: 'white' }}
			selectedKeys={[selectedMenu?.key]}
			items={items}
			onClick={selectedMenuItem}
		></Menu>
	);
}
